require('./bootstrap');

var lp, rp, mItems, menu, sc, pos;
lp = $(".left-pointer");
rp = $(".right-pointer");
mItems = $(".menu-item");
var links = mItems.children('button');

links.on('click', function(){
    links.removeClass('active');
    $(this).addClass('active');
});

lp.click(function(){
    sc = mItems.width() - 60;
    pos = mItems.scrollLeft() - sc;
    mItems.animate({'scrollLeft': pos}, 'slow');
});
rp.click(function(){
    sc = mItems.width() - 60;
    pos = mItems.scrollLeft() + sc;
    mItems.animate({'scrollLeft': pos}, 'slow');
});

mItems.scroll(function(){
    if( $(this).scrollLeft() == 0){
        $(".left-pointer").addClass("dis");
    }else{
        $(".left-pointer").removeClass("dis");
    }
});



var dynamic1 = $('#products-h');
var static1 = $('#filters-h');

//if(dynamic1.height() >= 200){
    static1.height(dynamic1.height());
//}





var Defaults = $.fn.select2.amd.require('select2/defaults');

$.extend(Defaults.defaults, {
    searchInputPlaceholder: ''
});

var SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search');

var _renderSearchDropdown = SearchDropdown.prototype.render;

SearchDropdown.prototype.render = function(decorated) {

    // invoke parent method
    var $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments));

    this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'));

    return $rendered;
};

$(document).ready(function () {

    $('#impos').on("change", function (e) {


        if ($('#impos').is(':checked')) {
            $("#inputSum").show();
            $("#payer").show();
        } else {
            $("#inputSum").hide();
            $("#payer").hide();
            $("#inputSum").val(0);
        }
    });

    let lang = $('html')[0].lang;
    if (lang !== 'ru') {
        lang = '';
    } else {
        lang = 'ru/';
    }

    $(document).on('click', ".add-to-cart", function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var id = $(this).attr("id");
        var qty = $(".input-card-change").val();

        if(qty === undefined){
            qty = 1;
        }

        var button = $(this);
        var cart = $('#cart-icon');

        button.addClass('item-added');
        setTimeout(function(){
            //button.removeClass('sendtocart');
            cart.addClass('shake');
            setTimeout(function(){
                cart.removeClass('shake');
            },500)
        },0)

        $.ajax({
            url: "/"+lang+"addtocart",
            method: 'POST',
            data: {
                id: id,
                qty: qty,
            },
            success: function (data) {

                //console.log($('#cart-badge').html());

                // $('#modal-body-cart').html(data);
                //$('#cart-badge').html($('#cart-badge').html());
                //$('#cart-badge').html(Number($('#cart-badge').html()) + 1);
                $('#cart-badge').html(data[0]);

                $('#'+id+'-cart-cnt').html(data[1]);




                //$("#myToast").toast("show");

            },
            error: function (data) {

            }
        });
    });

    $(".product-to-cart ").click(function () {

        $('.prod-item-added').show();


        $(this).hide();
    });

// $(document).ready(function () {
//     $(".add-to-cart").click(function () {
//
//         // var cartCount = Number($('#cart-badge').html()) + 1;
//         $('#cart-badge').html(Number($('#cart-badge').html()) + 1);
//         // console.log(cartCount);
//
//         //$('#cart-badge').html() = cartCount;
//
//         //$('#cart-badge').html() = $('#cart-badge').html() + 1;
//         $("#myToast").toast("show");
//
//     });
// });

    $(document).on('change', '.search-filter-check', function () {

        var search_id = $('.search-id').attr("id");

        var selected_attr = [];
        $('.filters-attr input:checked').each(function () {
            selected_attr.push($(this).attr('data-id'));
        });

        var selected_brands = [];
        $('.filter-brand input:checked').each(function () {
            selected_brands.push($(this).attr('data-id'));
        });

        if ((selected_attr.length === 0) && (selected_brands.length === 0)) {
            window.location = window.location.pathname + "?search=" + search_id;
            return false;
        }

        //$(".filters-list").html(selected_brands + selected_attr);


        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        history.pushState(null, null, '?page=' + page);

        getSearchProducts(page, search_id, selected_attr, selected_brands);

    });

    $(document).on('change', '.search-sort', function () {

        var search_id = $('.search-id').attr("id");

        var selected_attr = [];
        $('.filters-attr input:checked').each(function () {
            selected_attr.push($(this).attr('data-id'));
        });

        var selected_brands = [];
        $('.filter-brand input:checked').each(function () {
            selected_brands.push($(this).attr('data-id'));
        });

        // if ((selected_attr.length === 0) && (selected_brands.length === 0)) {
        //     window.location = window.location.pathname;
        //     return false;
        // }

        //var cat_id = $('.cat-id').attr("id");

        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        history.pushState(null, null, '?search=' + search_id + '&page=' + page);


        getSearchProducts(page, search_id, selected_attr, selected_brands);

    });

    $(document).on('change', '.brand-sort', function () {

        var search_id = $('.brand-id').attr("id");

        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        history.pushState(null, null, '?search=' + search_id + '&page=' + page);


        getBrandProducts(page, search_id);

    });

    function getBrandProducts(page, search_id) {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        let order = $('.brand-sort :selected').val();

        $.ajax({
            url: "/"+lang+"brand-ajax?search=" + search_id + "&page=" + page + '&order=' + order,
            method: "GET",
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (data) {
                //console.log(data);
                $('#products_ajax').html(data);
                // $("#sort option[value="+order+"]").attr('selected', 'selected');
                $('.loading-overlay').fadeOut("slow");
            }
        });
    }

    function getSearchProducts(page, search_id, selected_attr, selected_brands) {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        let order = $('.search-sort :selected').val();

        $.ajax({
            url: "/"+lang+"search-ajax?search=" + search_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order,
            method: "GET",
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (data) {
                //console.log(data);
                $('#products_ajax').html(data);
                // $("#sort option[value="+order+"]").attr('selected', 'selected');
                $('.loading-overlay').fadeOut("slow");
            }
        });
    }

    $(document).on('change', '.filter-check', function () {

        var filters_list = [];

        var selected_attr = [];
        $('.filters-attr input:checked').each(function () {
            selected_attr.push($(this).attr('data-id'));
            filters_list.push('<li class="list-group-item">'+$(this).val()+'</li>');
            //console.log($(this).val());
        });

        var selected_brands = [];
        $('.filter-brand input:checked').each(function () {
            selected_brands.push($(this).attr('data-id'));
            filters_list.push('<li class="list-group-item rounded-0">'+$(this).val()+'</li>');
            //console.log($(this).val());
        });

        if ((selected_attr.length === 0) && (selected_brands.length === 0)) {
            window.location = window.location.pathname;
            return false;
        }

        //console.log(filters_list);

        // if(filters_list.length > 0){
        //     $(".filters-list").html(filters_list);
        //     $("#filter-reset").addClass( "d-md-block");
        // }else{
        //     $("#filter-reset").removeClass( "d-md-block");
        // }




        var cat_id = $('.cat-id').attr("id");

        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        history.pushState(null, null, '?page=' + page);


        getProducts(page, cat_id, selected_attr, selected_brands);

    });

    $(document).on('change', '.sort', function () {

        var selected_attr = [];
        $('.filters-attr input:checked').each(function () {
            selected_attr.push($(this).attr('data-id'));
        });

        var selected_brands = [];
        $('.filter-brand input:checked').each(function () {
            selected_brands.push($(this).attr('data-id'));
        });

        // if ((selected_attr.length === 0) && (selected_brands.length === 0)) {
        //     window.location = window.location.pathname;
        //     return false;
        // }

        var cat_id = $('.cat-id').attr("id");

        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        history.pushState(null, null, '?page=' + page);


        getProducts(page, cat_id, selected_attr, selected_brands);

    });

    $(document).on('click', ".ajax_links .pagination a", function (e) {
        e.preventDefault();

        var selected_attr = [];
        $('.filters-attr input:checked').each(function () {
            selected_attr.push($(this).attr('data-id'));
        });

        // var selected_attr = [];
        // $('.filters input:checked').each(function () {
        //     selected_attr.push($(this).attr('data-id'));
        // });

        var selected_brands = [];
        $('.filter-brand input:checked').each(function () {
            selected_brands.push($(this).attr('data-id'));
        });

        //if (selected_attr.length === 0 && (selected_brands.length === 0)) {
        //window.location = this.href;
        //} else {

        var page = $(this).attr('href').split('page=')[1];
        var cat_id = $('.cat-id').attr("id");

        if (cat_id == null) {

            var brand_id = $('.brand-id').attr("id");
            if(brand_id != null){

                var search_id = $('.brand-id').attr("id");
                //let page = 1;
                //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);
                history.pushState(null, null, '?page=' + page);
                getBrandProducts(page, search_id);

            }else{
                var search_id = $('.search-id').attr("id");
                //let page = 1;
                //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);
                history.pushState(null, null, '?page=' + page);
                getSearchProducts(page, search_id, selected_attr, selected_brands);
            }

        }

        else {

            //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

            history.pushState(null, null, '?page=' + page);

            getProducts(page, cat_id, selected_attr, selected_brands);

        }

        $("html, body").animate({scrollTop: 0}, "slow");


        //}

        // $('li').removeClass('active');
        // $(this).parent('li').addClass('active');

    });

    function getProducts(page, cat_id, selected_attr, selected_brands) {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        let order = $('.sort :selected').val();

        var up_cat = $('.up-cat').attr('id');

        var url =  "/"+lang+"category/ajax?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order;

        if(up_cat != undefined){
            var url = "/"+lang+"category-all/ajax?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order;
        }

        $.ajax({
            //url: "/"+lang+"category/ajax?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order,
            url: url,
            method: "GET",
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (data) {
                //console.log(data);
                $('#products_ajax').html(data);
                // $("#sort option[value="+order+"]").attr('selected', 'selected');
                $('.loading-overlay').fadeOut("slow");
            }
        });
    }

    $(document).on('click', '.treeClick', function () {

        var selected_attr = [];

        var selected_brands = [];

        var cat_id = $(this).attr('id');
        var cat_slug = $(this).attr('data-id')

        //alert(cat_slug);

        let page = 1;
        //history.pushState(null, null, '?page=' + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands);

        //history.pushState(null, null, '?page=' + page);

        history.pushState(null, '/category/'+cat_slug, '/category/'+cat_slug);

        getProductsTree(page, cat_id, selected_attr, selected_brands);

    });

    function getProductsTree(page, cat_id, selected_attr, selected_brands) {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        let order = $('.sort :selected').val();

        var up_cat = $('.up-cat').attr('id');

        var url =  "/"+lang+"category/ajax-tree?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order;

        if(up_cat != undefined){
            var url = "/"+lang+"category-all/ajax-tree?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order;
        }

        $.ajax({
            //url: "/"+lang+"category/ajax?category=" + cat_id + "&page=" + page + '&filters_attr=' + selected_attr + '&filters_brands=' + selected_brands + '&order=' + order,
            url: url,
            method: "GET",
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (data) {
                //console.log(data);



                var html_cat_rend = $(data).find(".cat-rend").html();
                var html_breadcrumbs  = $(data).find(".breadcrumbs ").html();
                var html_pr_gr = $(data).find(".pr-gr").html();
                var html_products_ajax = $(data).find("#products_ajax").html();


                //alert(html_products_ajax);

                //$('#tree_rend').html(data);

                $('.cat-rend').html(html_cat_rend);
                $('.breadcrumbs').html(html_breadcrumbs);
                $('.pr-gr').html(html_pr_gr);
                $('#products_ajax').html(html_products_ajax);

                // $("#sort option[value="+order+"]").attr('selected', 'selected');
                $('.loading-overlay').fadeOut("slow");
            }
        });
    }



    $(document).on('click', ".cart-delete", function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var id = $(this).attr("id");

        $.ajax({
            url: "/"+lang+"delete-from-cart",
            method: 'POST',
            data: {
                id: id,
            },
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (result) {

                if(result == 0){
                    window.location.reload();
                }

                $('.cart').html(result);
                $('#cart-badge').html($('#cart-qty').html());
                $('.loading-overlay').fadeOut("slow");
            },
            error: function (data) {
            }
        });
    });

    $(document).on("click", '.prod-btn-minus', function () {
        var id = $(this).closest('div').find('input').attr("data-id");
        var value = $(this).closest('div').find('input');
        var count = parseInt(value.val()) - 1;
        count = count < 1 ? 1 : count;
        value.val(count);
        updateCardProducts(id, value.val());
        return false;
    });

    $(document).on("click", '.prod-btn-plus', function () {
        var id = $(this).closest('div').find('input').attr("data-id");
        var value = $(this).closest('div').find('input');
        value.val(parseInt(value.val()) + 1);
        updateCardProducts(id, value.val());
        return false;
    });

    $(document).on("change paste", '.input-card-change', function () {
        var id = $(this).attr("data-id");
        var qty = $(this).val();
        qty = qty < 1 ? 1 : qty;
        updateCardProducts(id, qty);
        return false;
    });

    function updateCardProducts(id, qty) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'update-card-qty',
            method: 'POST',
            data: {
                id: id,
                qty: qty,
            },
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (result) {
                $('.input-card-change').val(result);
                $('.loading-overlay').fadeOut("slow");
            },
            error: function (data) {
            }
        });
    }

    $(document).on("change paste", '.inputchange', function () {
        var id = $(this).attr("data-id");
        var qty = $(this).val();
        qty = qty < 1 ? 1 : qty;
        updateProducts(id, qty);
        return false;
    });

    $(document).on("click", '.btn-minus', function () {
        var id = $(this).closest('div').find('input').attr("data-id");
        var value = $(this).closest('div').find('input');
        var count = parseInt(value.val()) - 1;
        count = count < 1 ? 1 : count;
        value.val(count);
        updateProducts(id, value.val());
        return false;
    });

    $(document).on("click", '.btn-plus', function () {
        var id = $(this).closest('div').find('input').attr("data-id");
        var value = $(this).closest('div').find('input');
        value.val(parseInt(value.val()) + 1);
        updateProducts(id, value.val());
        return false;
    });

    function updateProducts(id, qty) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'update-qty',
            method: 'POST',
            data: {
                id: id,
                qty: qty,
            },
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (result) {
                $('.cart').html(result);

                // $(".inputchange :input").each(function(){
                //     var input = $(this);
                // });

                $('#cart-badge').html($('#cart-qty').html());
                $("#cod_sum").val($("#drop-sum").attr('data-id'));
                $('.loading-overlay').fadeOut("slow");
            },
            error: function (data) {
            }
        });
    }

    $('#save-order').on('click', function (e) {

        e.preventDefault();

        var cod = 0;
        if ($('#impos').is(':checked')) {
            var cod = $('#cod_sum').val();
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'save-order',
            method: 'POST',
            data: {
                username: $('#username').val(),
                phone: $('#phone').val(),
                comment: $('#comment').val(),
                delivery_type: $('#delivery_type').find(":selected").val(),
                cod: cod,
                city: $('#search_city option:last').html(),
                city_ref: $('#search_city option:last').attr('title'),
                ware: $('#search_ware option:last').html(),
                ware_ref: $('#search_ware option:last').attr('title'),

                street: $('#search_street option:last').html(),
                street_ref: $('#search_street option:last').attr('title'),
                house_number: $('#house_number').val(),

                np_delivery_type: $('#np_delivery_type').find(":selected").val(),

                payment_type: $('#payment_type').find(":selected").val(),
                user: $('#search_user').find('option:selected').attr('title'),
            },
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (result) {

                //alert(result);

                if (result == 0){
                    window.location.reload();
                }

                $('.save-order').html(result);
                $('.loading-overlay').fadeOut("slow");
                $("html, body").animate({scrollTop: 0}, "slow");
            },
            error: function (data) {


                $('.loading-overlay').fadeOut("slow");

                var payment_type_error = data.responseJSON.errors.payment_type;
                if (Array.isArray(payment_type_error) && payment_type_error.length) {
                    $('#payment_type').addClass('is-invalid');
                } else {
                    $('#payment_type').removeClass('is-invalid');
                }

                var delivery_type_error = data.responseJSON.errors.delivery_type;
                if (Array.isArray(delivery_type_error) && delivery_type_error.length) {
                    $('#delivery_type').addClass('is-invalid');
                } else {
                    $('#delivery_type').removeClass('is-invalid');
                }

                var username_error = data.responseJSON.errors.username;
                if (Array.isArray(username_error) && username_error.length) {
                    $('#username-label').html(data.responseJSON.errors.username);
                    $('#username').addClass('is-invalid');
                } else {
                    $('#username-label').html($('#username').attr('placeholder'));
                    $('#username').removeClass('is-invalid');
                }

                var phone_error = data.responseJSON.errors.phone;
                if (Array.isArray(phone_error) && phone_error.length) {
                    $('#phone-label').html(data.responseJSON.errors.phone);
                    $('#phone').addClass('is-invalid');
                } else {
                    $('#phone-label').html($('#phone').attr('placeholder'));
                    $('#phone').removeClass('is-invalid');
                }

                $("html, body").animate({scrollTop: $('.scr-t').offset().top}, "slow");

            }
        });
    });

    $(document).on('click', '#filter-reset', function () {
        window.location = window.location.pathname;
        return false;
    });

//////////////////////
//Cart->Delivery//////
//////////////////////

    $(document).ready(function () {
        $('#delivery_type').on("change", function (e) {
            var delivery_type = $(this).find(":selected").val();
            if (delivery_type == 2) {
                $('#np').removeClass('d-none').addClass('d-block');
                $('#np_department').removeClass('d-none').addClass('d-block');
                $("#impos").prop('checked', true);
                $('#inputSum').show();
                //console.log($("#drop-sum").attr('data-id'));
                $("#cod_sum").val($("#drop-sum").attr('data-id'));
                //$("#cod_sum").val($("#drop-sum").val());
            } else {
                $('#np').removeClass('d-block').addClass('d-none');
                $("#impos").prop('checked', false);
            }
        });
    });

    $(document).ready(function () {
        $('#np_delivery_type').on("change", function (e) {
            var np_delivery_type = $(this).find(":selected").val();
            if (np_delivery_type == 2) {
                $('#np_street').removeClass('d-none').addClass('d-block');
                $('#np_department').removeClass('d-block').addClass('d-none');
                $("#impos").prop('checked', true);
                $('#inputSum').show();
                //console.log($("#drop-sum").attr('data-id'));
                $("#cod_sum").val($("#drop-sum").attr('data-id'));
                //$("#cod_sum").val($("#drop-sum").val());
            } else {
                $('#np_street').removeClass('d-block').addClass('d-none');
                $('#np_department').removeClass('d-none').addClass('d-block');
                $("#impos").prop('checked', false);
            }
        });
    });

    $(document).ready(function () {
        $('#np_delivery_type_admin').on("change", function (e) {
            var np_delivery_type = $(this).find(":selected").val();
            if (np_delivery_type == 2) {
                $('#np_street').removeClass('d-none').addClass('d-block');
                $('#np_department').removeClass('d-block').addClass('d-none');
                // $("#impos").prop('checked', true);
                // $('#inputSum').show();
                // //console.log($("#drop-sum").attr('data-id'));
                // $("#cod_sum").val($("#drop-sum").attr('data-id'));
                //$("#cod_sum").val($("#drop-sum").val());
            } else {
                $('#np_street').removeClass('d-block').addClass('d-none');
                $('#np_department').removeClass('d-none').addClass('d-block');
                // $("#impos").prop('checked', false);
            }
        });
    });

    $("#search_city").select2({
        searchInputPlaceholder: "Пошук...",
        ajax: {
            url: "/np/city-search",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    search: params.term // search term
                };
            },
            beforeSend : function()    {
                //$('.loading-overlay').show();
            },
            processResults: function (response) {
                //$('.loading-overlay').fadeOut("slow");
                return {
                    results: response
                };
            },
            cache: true
        }
    });

    $(document).on('keyup change', '#city', function (e) {
        e.preventDefault();
        var search = $(this).val();
        if (search.length > 2) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: "/np/city-search",
                method: "POST",
                data: {
                    search: search,
                },
                beforeSend: function () {
                    $('.loading-overlay').show();
                },
                success: function (data) {
                    search = data;
                    //$('.admin_products_ajax').html(data);
                    $('.loading-overlay').fadeOut("slow");
                }
            });
        }
    });

    $("#search_ware").select2({
        searchInputPlaceholder: "Пошук...",
        ajax: {
            url: "/np/warehouses-search",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    search: $('#search_city option:last').attr('title'),
                    search_ware: params.term
                    //search: $('#search_city option:last').attr('title') +':'+ params.term,
                    //search: $('#search_city option:last').attr('title'),
                };
            },
            beforeSend : function()    {
                //$('.loading-overlay').show();
            },
            processResults: function (response) {
                //$('.loading-overlay').fadeOut("slow");
                return {
                    results: response
                };
            },
            cache: true
        }
    });

    $("#search_street").select2({
        searchInputPlaceholder: "Пошук...",
        ajax: {
            url: "/np/street-search",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    search: $('#search_city option:last').attr('title'),
                    search_ware: params.term

                };
            },
            beforeSend : function()    {
                //$('.loading-overlay').show();
            },
            processResults: function (response) {
                //$('.loading-overlay').fadeOut("slow");
                return {
                    results: response
                };
            },
            cache: true
        }
    });

    $("#search_ware_admin").select2({
        searchInputPlaceholder: "Пошук...",
        //allowClear: true,
        ajax: {
            url: "/np/warehouses-search-admin",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    //search: $('#search_city option:last').attr('title') +':'+ params.term,
                    search: $('#search_city option:last').attr('title'),
                    search_ware_admin: params.term
                };
            },
            beforeSend : function()    {
                //$("#search_ware_admin").select2('data', null);
                $('.loading-overlay').show();
            },
            processResults: function (response) {
                $('.loading-overlay').fadeOut("slow");
                return {
                    results: response
                };
            },
            cache: false
        }
    });

    $("#search_street_admin").select2({
        searchInputPlaceholder: "Пошук...",
        //allowClear: true,
        ajax: {
            url: "/np/streets-search-admin",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    //search: $('#search_city option:last').attr('title') +':'+ params.term,
                    search: $('#search_city option:last').attr('title'),
                    search_ware_admin: params.term
                };
            },
            beforeSend : function()    {
                //$("#search_ware_admin").select2('data', null);
                $('.loading-overlay').show();
            },
            processResults: function (response) {
                $('.loading-overlay').fadeOut("slow");
                return {
                    results: response
                };
            },
            cache: false
        }
    });


    $("#search_user").select2({
        searchInputPlaceholder: "Пошук...",
        ajax: {
            url: "/admin/user-search",
            type: "post",
            dataType: 'json',
            delay: 500,
            data: function (params) {
                return {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    search: params.term // search term
                };
            },
            processResults: function (response) {
                return {
                    results: response
                };
            },
            cache: true
        }
    });

    $("#search_user").change(function(e) {
        e.preventDefault();

        var user = $(this).find('option:selected').text();
        //var user = $('#search_user').find('option:selected').text();

        //console.log(user);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            //url: '/'+lang+'update-qty',
            url: '/update-all-cart',
            method: 'POST',
            data: {
                user: user,
            },
            beforeSend: function () {
                $('.loading-overlay').show();
            },
            success: function (result) {
                $('.cart').html(result);
                $('#cart-badge').html($('#cart-qty').html());
                $('.loading-overlay').fadeOut("slow");
            },
            error: function (data) {
            }
        });

    });

//////////////////
//Cart->End///////
//////////////////


    $(document).on('click', '#save_pass', function (e) {
        e.preventDefault();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/' + lang + 'cabinet/save-pass',
            method: 'POST',
            data: {
                oldpass: $('#inputOldPass').val(),
                newPass: $('#inputNewPass').val(),
                newPassAgain: $('#inputNewPassAgain').val(),
            },
            success: function (result) {
                $('#save_password_render').html(result);
                $('#alert-pass').removeClass('d-none');
            },
            error: function (data) {

                $('#alert-ai').addClass('d-none');

                var oldpass_type_error = data.responseJSON.errors.oldpass;
                if (Array.isArray(oldpass_type_error) && oldpass_type_error.length) {

                    $('#inputOldPass-label').html(data.responseJSON.errors.oldpass);
                    $('#inputOldPass').addClass('is-invalid');
                    $('#inputOldPass-label').addClass('text-danger');
                } else {
                    $('#inputOldPass-label').html($('#inputOldPass-label').attr('content'));
                    $('#inputOldPass').removeClass('is-invalid');
                    $('#inputOldPass-label').removeClass('text-danger');
                }

                var newPass_error = data.responseJSON.errors.newPass;
                if (Array.isArray(newPass_error) && newPass_error.length) {
                    $('#inputNewPass-label').html(data.responseJSON.errors.newPass);
                    $('#inputNewPass').addClass('is-invalid');
                    $('#inputNewPass-label').addClass('text-danger');
                } else {
                    $('#inputNewPass-label').html($('#inputNewPass-label').attr('content'));
                    $('#inputNewPass').removeClass('is-invalid');
                    $('#inputNewPass-label').removeClass('text-danger');
                }

                var newPassAgain_error = data.responseJSON.errors.newPassAgain;
                if (Array.isArray(newPassAgain_error) && newPassAgain_error.length) {
                    $('#inputNewPassAgain-label').html(data.responseJSON.errors.newPassAgain);
                    $('#inputNewPassAgain').addClass('is-invalid');
                    $('#inputNewPassAgain-label').addClass('text-danger');
                } else {
                    $('#inputNewPassAgain-label').html($('#inputNewPassAgain-label').attr('content'));
                    $('#inputNewPassAgain').removeClass('is-invalid');
                    $('#inputNewPassAgain-label').removeClass('text-danger');
                }

            }
        });
    });

    $(document).on('click', '#save_ai', function (e) {
        e.preventDefault();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/' + lang + 'cabinet/save-ai',
            method: 'POST',
            data: {
                email: $('#inputEmail').val(),
                phone: $('#inputPhone').val(),
                name: $('#inputName').val(),
            },
            success: function (result) {
                $('#save_id_render').html(result);
                $('#alert-ai').removeClass('d-none');
            },
            error: function (data) {

                $('#alert-ai').addClass('d-none');

                var email_type_error = data.responseJSON.errors.email;
                if (Array.isArray(email_type_error) && email_type_error.length) {

                    $('#inputEmail-label').html(data.responseJSON.errors.email);
                    $('#inputEmail').addClass('is-invalid');
                    $('#inputEmail-label').addClass('text-danger');
                } else {
                    $('#inputEmail-label').html($('#inputEmail-label').attr('content'));
                    $('#inputEmail').removeClass('is-invalid');
                    $('#inputEmail-label').removeClass('text-danger');
                }

                var phone_error = data.responseJSON.errors.phone;
                if (Array.isArray(phone_error) && phone_error.length) {
                    $('#inputPhone-label').html(data.responseJSON.errors.phone);
                    $('#inputPhone').addClass('is-invalid');
                    $('#inputPhone-label').addClass('text-danger');
                } else {
                    $('#inputPhone-label').html($('#inputPhone-label').attr('content'));
                    $('#inputPhone').removeClass('is-invalid');
                    $('#inputPhone-label').removeClass('text-danger');
                }

                var name_error = data.responseJSON.errors.name;
                if (Array.isArray(name_error) && name_error.length) {
                    $('#inputName-label').html(data.responseJSON.errors.name);
                    $('#inputName').addClass('is-invalid');
                    $('#inputName-label').addClass('text-danger');
                } else {
                    $('#inputName-label').html($('#inputName-label').attr('content'));
                    $('#inputName').removeClass('is-invalid');
                    $('#inputName-label').removeClass('text-danger');
                }

            }
        });
    });

    var result = $('#search-result');

    $(document).on('keyup', '#search', function (e) {
        e.preventDefault();

        var search = $(this).val();

        if (search.length > 2) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: "/" + lang + "search/ajax",
                method: "POST",
                data: {
                    search: search,
                },
                beforeSend: function () {
                    //$('.loading-overlay').show();
                },
                success: function (data) {
                    //search = data;


                    result.html(data);
                    result.fadeIn();


                    //$('.loading-overlay').fadeOut("slow");
                }
            });
        } else {
            result.html('');
            result.fadeOut(100);
        }
    });

    $(document).on('click', function (e) {
        if (!$(e.target).closest('.search_box').length) {
            result.html('');
            result.fadeOut();
        }
    });

    $(".rotate").click(function(){
        $(this).toggleClass("down");
    });

    $("#show-search").click(function() {
        var search_width = $('.fa-user-gear').offset().left - $('.fa-bars-staggered').offset().left;

        if($("#searchBox").hasClass('active'))
        {
            $('.search-container').removeClass('active');
            $('#search').width("");
        }
        else
        {
            $('.search-container').addClass('active');
            $('#search').width(search_width - 20);
        }
    });

    $(document).on('change', '.drop-price', function () {

        var this_id = $(this).attr("data-id");
        var this_price = $(this).val();

        item = {}
        $(".drop-price").each(function() {
            var id = $(this).attr("data-id");
            var price = $(this).val();
            item [id] = price;
        });

        jsonString = JSON.stringify(item);

        console.log(lang);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'drop-update-price',
            method: 'POST',
            data: {
                this_id: this_id,
                this_price: this_price,
                all: jsonString,
            },
            beforeSend: function () {
                //$('.loading-overlay').show();
            },
            success: function (result) {

                //$('.drop-price').blur();

                //console.log(result);
                $('.cart').html(result);
                $("#cod_sum").val($("#drop-sum").attr('data-id'));
                // $('#cart-badge').html($('#cart-qty').html());
                // $('.loading-overlay').fadeOut("slow");
            },
            error: function (data) {
            }
        });
    });

    $(document).on('click', '.star', function (e) {

        var rating = $(this).attr('id');
        var product_id = $('.rating').attr('id');


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: "/product-rating",
            method: "POST",
            data: {
                product_id: product_id,
                rating: rating,
            },
            beforeSend: function () {
                $('.loading-overlay-absolute').show();
                //$('.loading-overlay').show();
            },
            success: function (data) {
                $('#product_rating_render').html(data);
                $('.loading-overlay-absolute').fadeOut("slow");
            }
        });

    });

    ////////comments_begin

    $("#toggle-comment").click(function() {
        if($("#add-comment-form").hasClass('d-none'))
        {
            $('#add-comment-form').removeClass('d-none');
            $('#toggle-comment').addClass('d-none');
        }
    });

    $("#auth-error").click(function() {
        if($("#auth-msg").hasClass('d-none'))
        {
            $('#auth-msg').removeClass('d-none');
        }
    });

    $("#add-comment").click(function() {

        var product_id  = $('#product-comment').attr('data-id');
        var name        = $('#username').val();
        var comment     = $('#product-comment').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'product-comment/add',
            method: 'POST',
            data: {
                product_id: product_id,
                comment: comment,
                name: name,
            },
            success: function (result) {
                //$('#add-comment-section').html(result);
                $('#coment-section-render').html(result);

                $('html, body').animate({
                    scrollTop: $('.h2-products').offset().top - 400
                }, 500);


                $('#add-comment-form').addClass('d-none');
                //$('#toggle-comment').removeClass('d-none');
                //$('#search').width("");


            },
            error: function (data) {

                $('#auth-msg').addClass('d-none');

                var comment_error = data.responseJSON.errors.comment;
                if (Array.isArray(comment_error) && comment_error.length) {

                    $('#product-comment-label').html(comment_error);
                    $('#product-comment').addClass('is-invalid');
                    $('#product-comment-label').addClass('text-danger');
                } else {
                    $('#product-comment-label').html($('#product-comment-label').attr('content'));
                    $('#product-comment').removeClass('is-invalid');
                    $('#product-comment-label').removeClass('text-danger');
                }
            }
        });
    });

    $(document).on('click', '.answear-comment', function (e) {

        var product_id  = $('#product_id').attr('id');
        var comment_id  = $(this).attr('id');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'product-comment/answear',
            method: 'POST',
            data: {
                parent_id: comment_id,
                product_id: product_id,
            },
            success: function (result) {
                $('#answear_'+comment_id).html(result);
                $('#answear_'+comment_id).removeClass('d-none');
            },
            error: function (data) {
            }
        });
    });


    $(document).on('click', '#add-answear', function (e) {

        var parent_id  = $(this).attr('data-id');
        var product_id  = $('#product_id').html();
        var comment     = $('#answear-comment'+parent_id).val();
        var name     = $('#username'+parent_id).val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'product-comment/add-answear',
            method: 'POST',
            data: {
                parent_id: parent_id,
                product_id: product_id,
                comment: comment,
                name: name,
            },
            success: function (result) {

                $('#coment-section-render').html(result);

                // $('#answear_'+comment_id).html(result);
                // $('#answear_'+comment_id).removeClass('d-none');
            },
            error: function (data) {
                var comment_error = data.responseJSON.errors.comment;
                if (Array.isArray(comment_error) && comment_error.length) {

                    $('#answear-comment-label'+parent_id).html(comment_error);
                    $('#answear-comment'+parent_id).addClass('is-invalid');
                    $('#answear-comment-label'+parent_id).addClass('text-danger');
                } else {
                    $('#answear-comment-label'+parent_id).html($('#answear-comment-label'+parent_id).attr('content'));
                    $('#answear-comment'+parent_id).removeClass('is-invalid');
                    $('#answear-comment-label'+parent_id).removeClass('text-danger');
                }
            }
        });
    });

    $(document).on('click', '.delete-comment', function (e) {

        var comment_id  = $(this).attr('id');
        var product_id  = $('#product_id').html();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/'+lang+'product-comment/delete',
            method: 'POST',
            data: {
                comment_id: comment_id,
                product_id: product_id,
            },
            success: function (result) {
                $('#coment-section-render').html(result);
            },
            error: function (data) {
            }
        });
    });

    ////////comments_end

    $("#toTop").click(function () {
        $("html, body").animate({scrollTop: 0}, 1000);
    });

    $('#save-ttn').on('click', function (e) {

        e.preventDefault();

        var cod = 0;
        if ($('#impos').is(':checked')) {
            var cod = $('#inputSum').val();
        }

        var weight = $('.weight').map((i, e) => e.value).get();



        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/np/save',
            method: 'POST',
            data: {
                fio: $('#inputFio').val(),
                phone: $('#inputPhone').val(),
                comment: $('#inputComment').val(),
                sum: cod,
                cost: $('#inputCost').val(),
                //city: $('#search_city option:last').html(),
                city: $('#search_city option:last').attr('title'),
                //city_ref: $('#search_city option:last').attr('title'),
                //ware: $('#search_ware_admin option:last').html(),
                ware: $('#search_ware_admin option:last').attr('title'),
                payer: $('#payer').find('option:selected').attr('value'),
                np_payer: $('#np_payer').find('option:selected').attr('value'),
                street: $('#search_street_admin option:last').attr('title'),
                house_number: $('#house_number').val(),

                np_type: $('#np_delivery_type_admin option:selected').val(),
                admin_api_user: $('#admin_api_user option:selected').val(),

                //ware_ref: $('#search_ware option:last').attr('title'),
                weight:weight,
                order_id:$('#order_id').val(),

            },
            beforeSend: function () {
                $('#save-ttn').css('visibility', 'hidden');
                //$('.loading-overlay').show();
            },
            success: function (result) {

                if (result == true){
                    window.location.reload();
                }else{
                    $('.save-ttn').html(result);
                }

                setTimeout(function() {
                    $('#save-ttn').css('visibility', 'visible');
                 }, 1000);


                //$('.save-ttn').html(result);
                //$('.loading-overlay').fadeOut("slow");
                //$("html, body").animate({scrollTop: 0}, "slow");
            },
            error: function (data) {

                setTimeout(function() {
                    $('#save-ttn').css('visibility', 'visible');
                 }, 1000);
                //$('#save-ttn').css('visibility', 'visible');

                //$(this).disabled = false;

                // $('.loading-overlay').fadeOut("slow");
                //
                // var payment_type_error = data.responseJSON.errors.payment_type;
                // if (Array.isArray(payment_type_error) && payment_type_error.length) {
                //     $('#payment_type').addClass('is-invalid');
                // } else {
                //     $('#payment_type').removeClass('is-invalid');
                // }
                //
                // var delivery_type_error = data.responseJSON.errors.delivery_type;
                // if (Array.isArray(delivery_type_error) && delivery_type_error.length) {
                //     $('#delivery_type').addClass('is-invalid');
                // } else {
                //     $('#delivery_type').removeClass('is-invalid');
                // }
                //
                // var username_error = data.responseJSON.errors.username;
                // if (Array.isArray(username_error) && username_error.length) {
                //     $('#username-label').html(data.responseJSON.errors.username);
                //     $('#username').addClass('is-invalid');
                // } else {
                //     $('#username-label').html($('#username').attr('placeholder'));
                //     $('#username').removeClass('is-invalid');
                // }
                //
                // var phone_error = data.responseJSON.errors.phone;
                // if (Array.isArray(phone_error) && phone_error.length) {
                //     $('#phone-label').html(data.responseJSON.errors.phone);
                //     $('#phone').addClass('is-invalid');
                // } else {
                //     $('#phone-label').html($('#phone').attr('placeholder'));
                //     $('#phone').removeClass('is-invalid');
                // }

            }



        });



    });


    $(document).on('click', ".add-to-wishlist", function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var this_button = $(this);
        var id = $(this).attr("id");

        var wishlist = $('#wishlist-icon');

        this_button.addClass('item-added');
        setTimeout(function(){
            wishlist.addClass('shake');
            setTimeout(function(){
                wishlist.removeClass('shake');
            },500)
        },0)

        $.ajax({
            url: "/"+lang+"add-to-wishlist",
            method: 'POST',
            data: {
                id: id,
            },
            success: function (data) {

                this_button.removeClass('add-to-wishlist');
                this_button.addClass('remove-from-wishlist');

                this_button.children("i").removeClass("fa-regular");
                this_button.children("i").addClass("fa-solid");

                $('#wishlist-badge').html(data);
                //$('#'+id+'-wishlist-cnt').html(data[1]);

            },
            error: function (data) {
            }
        });
    });

    $(document).on('click', ".remove-from-wishlist", function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var this_button = $(this);
        var id = $(this).attr("id");

        var wishlist = $('#wishlist-icon');

        this_button.addClass('item-added');
        setTimeout(function(){
            wishlist.addClass('shake');
            setTimeout(function(){
                wishlist.removeClass('shake');
            },500)
        },0)

        $.ajax({
            url: "/"+lang+"remove-from-wishlist",
            method: 'POST',
            data: {
                id: id,
            },
            success: function (data) {
                this_button.removeClass('remove-from-wishlist');
                this_button.addClass('add-to-wishlist');
                this_button.children("i").removeClass("fa-solid");
                this_button.children("i").addClass("fa-regular");
                $('#wishlist-badge').html(data);
            },
            error: function (data) {
            }
        });
    });

});
